<template>
  <div>
    <CRow>
      <CCol col>
        <CCard>
          <CCardHeader>
            <strong> Modulo Reduccion de Cuotas - Nuevo</strong>

            <router-link class="btn btn-light float-right btn-sm"  :to="{ path: '/reduccion-cuotas/listar' }" append ><i class="fa fa-chevron-left"></i> Regresar</router-link>
          </CCardHeader>
          <CCardBody>
            <b-form id="Form" autocomplete="off" @submit.prevent="Validate">
              <b-row>

                <b-col lg="6">
                  <b-form-group>
                    <label>Crédito:</label>
                    <v-select @input="GetCreditByID" placeholder="Seleccione un crédito" class="w-100" :filterable="false" label="text" v-model="client" @search="SearchCredit" :options="clients"></v-select>
                    <small v-if="errors.id_credit" class="form-text text-danger" >Seleccione un credito</small>
                  </b-form-group>
                </b-col>
                
                <b-col md="2">
                  <b-form-group label="Codigo:">
                    <b-form-input readonly class="text-center" type="text" v-model="credit_fee_reduction.code"></b-form-input>
                    <small v-if="errors.code"  class="form-text text-danger" >Seleccione un código</small>
                  </b-form-group>
                </b-col>


                <b-col md="2">
                  <b-form-group label="Medio de pago:">
                    <b-form-select v-model="credit_fee_reduction.payment_method" :options="payment_method"></b-form-select>
                    <small v-if="errors.payment_method"  class="form-text text-danger" >Medio de Pago</small>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Fecha:">
                    <b-form-input type="date" disabled class="text-center" v-model="credit_fee_reduction.date"></b-form-input>
                    <small v-if="errors.date"  class="form-text text-danger" >Ingrese un fecha</small>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Nro Cuota:">
                    <b-form-input disabled type="text" class="text-center" v-model="credit_fee_reduction.quota_number"></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Saldo Capital:">
                    <b-form-input disabled type="number" step="any" class="text-right" v-model="credit_fee_reduction.balance"></b-form-input>
                  </b-form-group>
                </b-col>


                <b-col md="2">
                  <b-form-group label="Amortización:">
                    <b-form-input  type="number" @change="UpdateTotalAmortization" step="any" class="text-right" v-model="credit_fee_reduction.total"></b-form-input>
                  </b-form-group>
                </b-col>


                <b-col md="2">
                  <b-form-group>
                    <label for="">Ultima Cuota</label>
                    <b-form-input type="number" step="any" class="text-right" v-model="credit_fee_reduction.dues"></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label=".">
                    <b-button type="button" class="form-control" variant="primary" title="Calcular Reducción de Cuota" @click="GetCreditDetailByReductionDues"><i class="fas fa-calendar"></i> Calcular Cronograma</b-button>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Doc. Adjunto:">
                    <b-form-file  accept="application/pdf"  v-model="file" :state="Boolean(file)" @change="onFileChange" placeholder="Selecciona un archivo" drop-placeholder="Arrastre el archivo aqui"></b-form-file>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group label="Observación:">
                    <b-form-textarea v-model="credit_fee_reduction.observation"></b-form-textarea>
                  </b-form-group>
                </b-col>


               


               
                <b-col md="12" >
                  <div class="alert alert-dark" role="alert">
                    <strong>CRONOGRAMA DE ṔAGO</strong> 
                  </div>
                </b-col>

                <b-col md="12">
                  <div class="table-responsive">
                    <table class="table table-hover table-bordered">
                      <thead>
                        <tr>
                          <th width="3%" class="text-center">#</th>
                          <th width="9%" class="text-center">Fecha</th>
                          <th width="7%" class="text-center">Cuota</th>
                          <th width="7%" class="text-center">Amortización</th>
                          <th width="7%" class="text-center">Capital</th>
                          <th width="7%" class="text-center">Interes</th>
                          <th width="7%" class="text-center">Saldo <br> Capital </th>
                          
                      
                        </tr>
                      </thead>
                      <tbody v-for="(item, it) in credit_fee_reduction.credits_details" :key="it">
                        <tr v-if="item.state == 1">
                          <td class="text-center">{{ item.quota_number }}</td>
                          <td class="text-center"> {{ item.date  }}</td>
                          <td class="text-right"> {{ item.quota  }}</td>
                          <td class="text-right"> {{ item.amortization  }}</td>
                          <td class="text-right"> {{ item.capital_balance  }}</td>
                          <td class="text-right"> {{ item.interest  }}</td>
                          <td class="text-right"> {{ item.balance  }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </b-col>
            
               

               

                <b-col md="5"></b-col>
                <b-col md="2">
                  <b-button type="submit" class="form-control" variant="primary" ><i class="fas fa-save"></i> Guardar (F4)</b-button>
                </b-col>
                
              </b-row>
            </b-form>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>


    <ModalClients />
    <LoadingComponent :is-visible="isLoading"/>
    <Keypress key-event="keyup" :key-code="115" @success="Validate" />
  </div>
</template>

<script>

import vSelect from "vue-select";
import 'vue-select/dist/vue-select.css';
import "vue-select/src/scss/vue-select.scss";


const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
var moment = require("moment");

import { mapState,mapMutations,mapActions } from "vuex";
import EventBus from "@/assets/js/EventBus";
import DataCollection from "@/assets/js/DataCollection";
// components
import ModalClients from './../components/ModalClient'
import LoadingComponent from './../pages/Loading'
export default {
  name: "CreditPaymentAdd",
  components:{
      vSelect,
      ModalClients,
      Keypress: () => import('vue-keypress'),
      LoadingComponent,
  },
  data() {
    return {
      isLoading: false,
      module: 'CreditFeeReduction',
      role: 2,
      credit_fee_reduction: {
          id_credit_fee_reduction:'',
          id_client:'',
          id_user:'',
          id_branch_office:'',
          id_credit:'',
          id_credit_detail:'',
          code:'',
          payment_method:'008',
          date:moment(new Date()).local().format("YYYY-MM-DD"),
          annexes:'',
          observation:'',
          quota:'0.00',
          amortization:'0.00',
          total:'0.00',
          dues:'0',
          state:1,
          quota_number : '',
          balance : '',
          interest : '',
          quota : '',
          credits_details : [],


          total_max:'',
          dues_max:'',
      },
      file:null,
      modules:[],
      // payment_method:[
      //   {value:'008', text:'EFECTIVO'},
      //   {value:'007', text:'CHEQUES'},
      //   {value:'001', text:'DEPÓSITO EN CUENTA'},
      //   {value:'003', text:'TRANSFERENCIA DE FONDOS'},
      // ],
      client: null,
      clients: [],
      errors: {
        id_client: false,
        payment_method: false,
        date: false,
        total: false,
        id_credit:false,
        id_credit_detail: false,
      },
      validate: false,
    };
  },
  mounted() {
    this.GetCorrelative();
  },
  methods: {
    GetCorrelative,
    GetCreditByID,
    GetCreditDetailByReductionDues,

    AddCreditFeeReduction,
    Validate,

    SearchCredit,
    onFileChange,
    UpdateTotalAmortization,

    OpenVoucher,
  },

  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
    user: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user;
    },
    payment_method: function () {
      return DataCollection.MethodPayment();
    }
  },
};

function onFileChange(e) {
  this.credit_fee_reduction.annexes = e.target.files[0];
}

function GetCorrelative() {
  let me = this;
  let url = me.url_base + "data/get-correlative/CreditFeeReduction";
  me.requirements = [];
  axios({
    method: "GET",
    url: url,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module, role: me.role },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.credit_fee_reduction.code = response.data.result.number;
      }
    });
}

function UpdateTotalAmortization() {
  this.credit_fee_reduction.total = this.credit_fee_reduction.total.length == 0 ? 0 : this.credit_fee_reduction.total;
  this.credit_fee_reduction.total =  parseFloat(this.credit_fee_reduction.total).toFixed(2);

}

function GetCreditDetailByReductionDues() {
  let me = this;
  let url = me.url_base + "credit-fee-reduction/credit-detail-reduction";
  this.credit_fee_reduction.dues = this.credit_fee_reduction.dues.length == 0 ? 0:this.credit_fee_reduction.dues;
  this.credit_fee_reduction.total = this.credit_fee_reduction.total.length == 0 ? 0:this.credit_fee_reduction.total;
  this.credit_fee_reduction.credits_details = [];
  if (this.client == null) {
    Swal.fire({ icon: 'warning', text: 'Seleccione un cliente', timer: 2000,}); 
    return false;
  }
  if (this.credit_fee_reduction.id_credit_detail == null) {
    Swal.fire({ icon: 'warning', text: 'Seleccione un credito', timer: 2000,}); 
    return false;
  }
  if (parseInt(this.credit_fee_reduction.dues) <= 0) {
    Swal.fire({ icon: 'warning', text: 'Ingrese una Cuota', timer: 2000,}); 
    return false;
  }
 
  if (parseFloat(this.credit_fee_reduction.total_max) < parseFloat(this.credit_fee_reduction.total)) {
    Swal.fire({ icon: 'warning', text: 'El monto ingresado excede al saldo capital del crédito', timer: 2000,}); 
    return false;
  }


  let data =  this.credit_fee_reduction;
  axios({
    method: "POST",
    url: url,
    data:data,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module, role: me.role },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.credit_fee_reduction.credits_details = response.data.result.credits_details;
        me.UpdateTotalAmortization();
      }
    });
}

function SearchCredit(search, loading) {
  
  let me = this;
   let url = this.url_base + "credit-fee-reduction/credits-actives";
   if (search !== "") {

    let data = {
      search : search
    };

     loading(true);
     axios({
       method: "POST",
       url: url,
       data: data,
       headers: { "Content-Type": "application/json", token: me.token, module: me.module,role: me.role },
     }).then(function (response) {
           me.clients = response.data.result;
           loading(false);
     })
   }
}

function AddCreditFeeReduction() {
  let me = this;
  let url = me.url_base + "credit-fee-reduction/add";
  me.isLoading = true;

  let data = new FormData();
  data.append("id_branch_office", this.user.id_branch_office);
  data.append("id_user", this.user.id_user);
  data.append("id_credit", this.credit_fee_reduction.id_credit);
  data.append("id_credit_detail", this.credit_fee_reduction.id_credit_detail);
  data.append("id_financial_data", this.credit_fee_reduction.id_financial_data);
  data.append("code", this.credit_fee_reduction.code);
  data.append("date", this.credit_fee_reduction.date);
  data.append("payment_method", this.credit_fee_reduction.payment_method);
  data.append("observation", this.credit_fee_reduction.observation);
  data.append("annexes", this.credit_fee_reduction.annexes);
  data.append("dues", this.credit_fee_reduction.dues);
  data.append("balance", this.credit_fee_reduction.balance);
  data.append("total", this.credit_fee_reduction.total);
  data.append("credits_detail", JSON.stringify(this.credit_fee_reduction.credits_detail));

  axios({
    method: "POST",
    url: url,
    data: data,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module,role: me.role },
  })
    .then(function (response) {
      if (response.data.status == 201) {
        me.OpenVoucher(response.data.result.id_credit_fee_reduction);
        me.$router.push({ name: "CreditFeeReductionList" });
        Swal.fire({ icon: 'success', text: response.data.message, timer: 3000,})
      }else{
        Swal.fire({ icon: 'error', text: response.data.message, timer: 3000,})
      }
      me.isLoading = false;
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      me.isLoading = true;
    });
}

function Validate() {
  this.errors.id_credit = this.credit_fee_reduction.id_credit.length == 0 ? true : false;
  this.errors.id_credit_detail = this.credit_fee_reduction.id_credit_detail.length == 0 ? true : false;
  this.errors.payment_method = this.credit_fee_reduction.payment_method.length == 0 ? true : false;
  this.errors.date = this.credit_fee_reduction.date.length == 0 ? true : false;
  this.errors.code = this.credit_fee_reduction.code.length == 0 ? true : false;
  this.errors.dues = this.credit_fee_reduction.dues.length == 0 || parseFloat(this.credit_fee_reduction.total) <= 0  ? true : false;
  this.errors.total = this.credit_fee_reduction.total.length == 0 || parseFloat(this.credit_fee_reduction.total) <= 0  ? true : false;
  
  if (this.errors.id_credit) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.id_credit_detail) { this.validate = true; Swal.fire({ icon: 'warning', text: 'No cuenta con cuotas por pagar', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.payment_method) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.date) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.code) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.total) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.dues) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }

  let me = this;
  Swal.fire({
    title: "Esta seguro de registrar la reducción de cuota del crédito?",
    text: "No podrás revertir esto!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Si, Estoy de acuerdo!",
  }).then((result) => {
    if (result.value) {
      this.AddCreditFeeReduction();
    }
  });
}

function GetCreditByID() {
  let me = this;

  me.credit_fee_reduction.id_credit = '';
  me.credit_fee_reduction.id_credit_detail = '';
  me.credit_fee_reduction.quota_number = '';
  me.credit_fee_reduction.balance = '';
  me.credit_fee_reduction.interest = '';
  me.credit_fee_reduction.quota = '0.00';
  me.credit_fee_reduction.amortization = '0.00';
  me.credit_fee_reduction.total = '0.00';
  me.credit_fee_reduction.dues = '';

  if (this.client == null) {
    return false;
  }
  let url = me.url_base + "credit-fee-reduction/credit-by-id";

  let data = {
    id_credit : this.client.value,
  }
  axios({
    method: "POST",
    url: url,
    data:data,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module, role: me.role },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.credit_fee_reduction.id_credit = response.data.result.credit.id_credit;
        me.credit_fee_reduction.quota_number = response.data.result.credit_detail.quota_number;
        me.credit_fee_reduction.balance = response.data.result.credit_detail.balance;
        me.credit_fee_reduction.interest = response.data.result.credit_detail.interest;
        me.credit_fee_reduction.id_credit_detail = response.data.result.credit_detail.id_credit_detail;

        me.credit_fee_reduction.total_max = response.data.result.credit_detail.balance;
        me.credit_fee_reduction.dues_max = response.data.result.credit.dues;
      }else{
        Swal.fire({ icon: 'error', text: response.data.message, timer: 3000,})
      }
    });
}


function OpenVoucher(id_credit_fee_reduction) {
  let me = this;
  let url = me.url_base + "credit-fee-reduction-sales-of-receip/"+ id_credit_fee_reduction;
  var ventana1 = window.open(url, '_blank');
}

</script>
